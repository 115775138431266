export const ENV_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const FIGMA_CLIENT_ID = process.env.FIGMA_CLIENT_ID;
export const FIGMA_CLIENT_SECRET = process.env.FIGMA_CLIENT_SECRET;
export const FIGMA_REDIRECT_URI = process.env.FIGMA_REDIRECT_URI;
export const FIGMA_STATE = process.env.FIGMA_STATE;
export const GA_TRACKER_ID = process.env.GA_TRACKER_ID;
export const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY;
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID;
export const API_URL = process.env.API_URL;
