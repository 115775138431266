import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';

import * as api from 'api/methods';

import { SocketContext } from 'context/socket';

import { useQuery } from 'hooks/useQuery';

import { convertGoogleDataToApi } from './OAuthPage.helpers';

import OAuthPage from './OAuthPage';

const OAuthPageContainer = () => {
  const googleBtnRef = useRef();
  const facebookBtnRef = useRef();

  const query = useQuery();

  const socket = useContext(SocketContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const data = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          },
        );

        const { user, token } = await api.registerUser(
          convertGoogleDataToApi(data),
        );

        socket.emit('WEB:GOOGLE_OAUTH_COMPLETED', { user, token });

        setLoading(false);
        setSuccess(true);
      } catch (err) {
        setLoading(false);
        setError(true);
      }
    },
    onError: (errorResponse) => {
      setLoading(false);
      setError(true);

      socket.emit('WEB:GOOGLE_OAUTH_ERROR', errorResponse);
    },
    onNonOAuthError: () => {
      setLoading(false);
      setError(true);
    },
  });

  useEffect(() => {
    const social = query.get('social');

    switch (social) {
      case 'google': {
        if (googleBtnRef.current) {
          googleBtnRef.current.click();
        }

        break;
      }
      case 'facebook': {
        if (facebookBtnRef.current) {
          facebookBtnRef.current.click();
        }

        break;
      }
    }
  }, [googleBtnRef.current, facebookBtnRef.current]);

  const handleFacebookCallback = async (data) => {
    console.log(data);
    // const { user, token } = await api.registerUser(
    //   convertGoogleDataToApi(data),
    // );
    // socket.emit('WEB:FACEBOOK_OAUTH_COMPLETED', { user, token });
  };

  const handleTryAgainClick = () => {
    const social = query.get('social');

    setLoading(true);
    setError(false);

    switch (social) {
      case 'google': {
        googleLogin();

        break;
      }
      case 'facebook': {
        break;
      }
    }
  };

  return (
    <OAuthPage
      loading={loading}
      success={success}
      error={error}
      googleLogin={googleLogin}
      googleBtnRef={googleBtnRef}
      facebookBtnRef={facebookBtnRef}
      onTryAgainClick={handleTryAgainClick}
      onFacebookCallback={handleFacebookCallback}
    />
  );
};

export default React.memo(OAuthPageContainer);
