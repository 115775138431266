export const ROUTES = {
  HOME_PAGE: '/',
  DASHBOARD_PAGE: '/dashboard',
  COURSES_PAGE: '/courses',
  COURSE_PAGE: '/courses/:id',
  COURSE_BEFORE_START_PAGE: '/courses/:id/before-start',
  COURSE_LESSON_PAGE: '/courses/:courseId/lessons/:lessonId',
  PROFILE_PAGE: '/account/profile',
  PRIVACY_POLICY_PAGE: '/privacy-policy',
  TERMS_PAGE: '/terms-of-use',
  FAQ_PAGE: '/faq',
  PRICING_PAGE: '/pricing',
  UNSUBSCRIBE_PAGE: '/unsubscribe',
  OAUTH_PAGE: '/oauth',
};
