import React from 'react';
import { NavLink } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';

import { FACEBOOK_APP_ID } from 'constants/env';

import Logo from 'assets/img/logo.svg';
import ErrorIcon from 'assets/img/error.svg';
import SuccessIcon from 'assets/img/success.svg';
import Loading from 'assets/icons/loading.svg';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import styles from './OAuthPage.styles.scss';

const OAuthPage = ({
  loading,
  error,
  success,
  googleLogin,
  googleBtnRef,
  facebookBtnRef,
  onFacebookCallback,
  onTryAgainClick,
}) => {
  return (
    <div className={styles.page}>
      <div className={styles.hideBtn} ref={googleBtnRef} onClick={googleLogin}>
        Google
      </div>
      <FacebookLogin
        cssClass={styles.hideBtn}
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={onFacebookCallback}
      />
      <div className={styles.hideBtn} ref={facebookBtnRef}>
        Facebook
      </div>
      <div className={styles.header}>
        <NavLink to="/" className={styles.logoLink}>
          <Logo className={styles.logo} />
        </NavLink>
      </div>
      <div className={styles.inner}>
        {loading && (
          <>
            <Loading className={styles.loading} />
            <Typography className={styles.title} variant="subtitle">
              Waiting for authorization
            </Typography>
          </>
        )}
        {!loading && error && (
          <>
            <ErrorIcon className={styles.icon} />
            <Typography className={styles.title} variant="subtitle">
              Something went wrong
            </Typography>
            <Typography className={styles.text} variant="body1">
              An error occurred during authorization
            </Typography>
            <Button className={styles.btn} size="md" onClick={onTryAgainClick}>
              Try again
            </Button>
          </>
        )}
        {!loading && success && (
          <>
            <SuccessIcon className={styles.icon} />
            <Typography className={styles.title} variant="subtitle">
              You’ve successfully authorized
            </Typography>
            <Typography className={styles.text} variant="body1">
              Please return to Befront Figma Plugin
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(OAuthPage);
