export const convertGoogleDataToApi = (data) => ({
  email: data.email,
  firstName: data.given_name,
  lastName: data.family_name,
  avatar: data.picture || '',
});

export const convertFacebookDataToApi = (data) => ({
  email: data.email,
  firstName: data.name.split(' ')[0],
  lastName: data.name.split(' ')[1],
  avatar: data.picture?.data?.url || '',
});
