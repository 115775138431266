import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Smartlook from 'smartlook-client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SocketContext, socket } from 'context/socket';

import { GOOGLE_CLIENT_ID } from 'constants/env';

import { getCookieEnabled } from 'helpers/getCookieEnabled';

import { AuthModalProvider } from 'context/authModals';

import { SessionProvider } from 'components/SessionProvider';
import { AnalyticsProvider } from 'components/AnalyticsProvider';
import { CookieBanner } from 'components/CookieBanner';

const App = () => {
  const [cookieEnabled, setCookieEnabled] = useState(getCookieEnabled());

  useEffect(() => {
    window.addEventListener('storage', () =>
      setCookieEnabled(() => getCookieEnabled()),
    );

    return () => {
      window.removeEventListener('storage', () =>
        setCookieEnabled(() => getCookieEnabled()),
      );
    };
  }, []);

  useEffect(() => {
    if (cookieEnabled === 'enabled') {
      Smartlook.init('a209b0f97d51bea1e5f5d705d30b11858511bfe6');
    }
  }, [cookieEnabled]);

  return (
    <>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <SocketContext.Provider value={socket}>
          <AnalyticsProvider>
            <AuthModalProvider>
              <SessionProvider>
                <Outlet />

                <ToastContainer
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                />
                {cookieEnabled === null && <CookieBanner />}
              </SessionProvider>
            </AuthModalProvider>
          </AnalyticsProvider>
        </SocketContext.Provider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
